import React, {Component} from "react"
import styles from "./Footer.module.css"

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <address className={styles.address}></address>
                    <p className={styles.text}>
                        <strong>Felbry College School of Nursing</strong>
                    </p>
                    <p className={styles.text}>6055 Cleveland Ave, Columbus, OH 43231</p>
                    <p className={styles.text}>
                        {" "}
                        <strong>Phone</strong>: <a href="tel:614-781-1085">614-781-1085</a> |{" "}
                        <strong>Fax</strong>: <a href="fax:+614-929-3816">614-929-3816</a>
                    </p>
                    <p className={styles.text}>info@felbrycollege.edu</p>
                </div>
            </footer>
        )
    }
}
