import * as React from "react"
import {Component} from "react"
import {Model} from "./Model"
import {route} from "./lib/functions/route"
import {Gate} from "./lib/gate/Gate"
import {AccountRecovery} from "./pages/account-recovery"
import {ResetPassword} from "./pages/reset-password"
import {Main} from "./pages/main"
// import classNames from "classnames";
// import styles from "./App.module.css";

interface Props {
    model: Model
    gate: Gate
}

export class App extends Component<Props, {}> {
    public render() {
        const {model} = this.props
        const path = model.router.path
        console.log(model.visitor)

        let html = <Main model={model} />

        if (route("/accounts/recovery(/)", path)) {
            html = <AccountRecovery model={model} />
        }

        if (route("/users/:id(/)*", path)) {
            const {id} = route("/users/:id(/)*", path)
            html = <h1>Users. User ID={id}</h1>
        }

        if (route("/docs/:id(/)", path)) {
            const {id} = route("/docs/:id(/)*", path)
            html = <h1>Documents. Document ID={id}</h1>
        }

        if (route("/accounts/reset(/)*", path)) {
            html = <ResetPassword model={model} />
        }
        return html
    }
}
